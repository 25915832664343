import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "../axios";
import "../assets/css/footer.css";

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};
const images = importAll(
  require.context("../assets/images", false, /\.(png|jpe?g|svg)$/)
);

export const Footer = () => {
  const [formInputSignUp, setFormInputSignUp] = useState("");
  const [alertSuccessSignUp, setAlertSuccessSignUp] = useState("");
  const [alertDangerSignUp, setAlertDangerSignUp] = useState("");

  const regExp = RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/);
  const handleChangeSignUp = (e) => {
    e.preventDefault();
    if (formInputSignUp !== '' && !regExp.test(formInputSignUp))
      setAlertDangerSignUp("Email is not valid!");
    setFormInputSignUp(e.target.value);
  };
  const handleSubmitSignUp = (e) => {
    e.preventDefault();
    setAlertDangerSignUp("");
    setAlertSuccessSignUp("");
    if (!formInputSignUp || formInputSignUp === "")
      setAlertDangerSignUp("Email cannot be blank!");
    else if (formInputSignUp !== '' && !regExp.test(formInputSignUp))
      setAlertDangerSignUp("Email is not valid!");
    if (alertDangerSignUp === "") {
      setFormInputSignUp("");
      axios
        .post("record/save-record", {
          saveData: {
            text_1: formInputSignUp,
          },
          tableId: process.env.REACT_APP_NWL_TABLE_ID,
        })
        .then((response) => {
          setAlertSuccessSignUp("Thank you for subscribing to Maragin.");
        })
        .catch((error) => {
          console.log("error", error);
          // setAlertDanger(error);
        });
    }
  };
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <ul className="common-links-footer common-links-footer-contact">
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    window.location.href = "tel:+1-650-743-6998";
                    e.preventDefault();
                  }}
                >
                  <img width="35" src={images["phone.svg"]} alt="+1 (650) 743 6998" />
                  <span>+1 (650) 743 6998</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    window.location.href = "mailto:info@maragin.org";
                    e.preventDefault();
                  }}
                >
                  <img width="35" src={images["gmail-logo.svg"]} alt="info@maragin.org" />
                  <span>info@maragin.org</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img width="35" 
                    src={images["place-marker.svg"]}
                    alt="1551 Corporate Dr. Irving, TX 75038"
                  />
                  <span>1551 Corporate Dr. Irving, TX 75038</span>
                </Link>
              </li>
            </ul>
            <ul className="common-links-social">
              <li>
                <a
                  href="https://www.linkedin.com/company/maragin-health-foundation/about/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-linkedin-in"></i>
                </a>
              </li>
              {/* <li>
                <a
                  href="https://twitter.com/MEDNAsci"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/mednascientific/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@mednasci"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-tiktok"></i>
                </a>
              </li> */}
            </ul>
          </div>
          <div className="col-md-2 mobile-hide"></div>
          <div className="col-md-3 mobile-hide">
            <h6>Quick Links</h6>
            <ul className="footer-menu-item">
              <li>
                <Link to="/contact-us">
                  <span>Contact Us</span>
                </Link>
              </li>
              <li>
                <Link to="/about/publications">
                  <span>Publications</span>
                </Link>
              </li>
              <li>
                <Link to="/about/news">
                  <span>News & Events</span>
                </Link>
              </li>
              <li>
                <Link to="/scientificprograms/overview">
                  <span>Scientific Programs Overview</span>
                </Link>
              </li>
              <li>
                <Link to="/meta/overview">
                  <span>Meta Analysis Overview</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-1 mobile-hide"></div>
          <div className="col-md-3 mobile-hide">
            <h6>Sign up for Emails</h6>
            <p className="footer-menu-item-para">
              Sign up to receive Maragin emails about products,
              services, and company updates.{" "}
            </p>
            {alertDangerSignUp && (
              <div className="alert alert-danger" role="alert">
                {alertDangerSignUp}
              </div>
            )}
            {alertSuccessSignUp && (
              <div className="alert alert-success" role="alert">
                {alertSuccessSignUp}
              </div>
            )}
            <form
              onSubmit={(e) => {
                handleSubmitSignUp(e);
              }}
              className="footer-menu-item-form"
            >
              <input
                className={alertDangerSignUp ? "error" : ""}
                type="email"
                placeholder="Enter Email"
                aria-label="email"
                name="email"
                value={formInputSignUp || ""}
                onChange={handleChangeSignUp}
              />
              <button className="common-buttons-medna">
                Subscribe{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <rect
                    y="15"
                    width="15"
                    height="15"
                    transform="rotate(-90 0 15)"
                    fill="url(#pattern0)"
                  />
                  <defs>
                    <pattern
                      id="pattern0"
                      patternContentUnits="objectBoundingBox"
                      width="1"
                      height="1"
                    >
                      <use
                        xlinkHref="#image0_9_100"
                        transform="scale(0.0166667)"
                      />
                    </pattern>
                    <image
                      id="image0_9_100"
                      width="60"
                      height="60"
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAABmJLR0QA/wD/AP+gvaeTAAABIUlEQVRoge3a0U7CMBiGYeYFOmM0HMi9Gw1iBO8AktcD1rjNdgNTCv3yPcdl/d9sI1nSxcIsK6AFtsAXcH/teS4O+ODXuvT+TekNAQYDNE3RGe5KbnYLHKzOweocrM7B6hyszsHqHKzOweocrM7B6hyszsHqHKzOweocrM7B6hyszsHqHKwuWzDwDHx3B88eMlyvBV6Bd6DNMWM2QAPseifs9sAysXYgsWbZXSPYXLbgH7qzk33R6LngSCzAW5mKMwCPkUH/RE8FJ2L3N/dIB8BTZOADsOqtiQZz/A+I/fblOjUnmouOBVcbG0xFj4Orjw1Iv49js+99NRJ3ekp9d3bsjOj6Y4MTonVig4lovdggEq0bG3D8KNgAn2T4yDAb+gEY/9gwdIHCygAAAABJRU5ErkJggg=="
                    />
                  </defs>
                </svg>
              </button>
            </form>
          </div>
          <div className="col-md-12 Copyright-text">
            <p>© Copyright Maragin. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
