import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "../axios";

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};
const images = importAll(
  require.context("../assets/images", false, /\.(png|jpe?g|svg)$/)
);

export const Contact = () => {
  const [formInputs, setFormInputs] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [alertSuccess, setAlertSuccess] = useState("");
  const [alertDanger, setAlertDanger] = useState("");

  const regExp = RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const newErrors = {};
    switch (name) {
      case "firstName":
        if (value.length < 2) newErrors.firstName = "First name is too short!";
        break;
      case "email":
        if (!regExp.test(value)) newErrors.email = "Email is not valid!";
        break;
      case "phone":
        if (value.length < 10) newErrors.phone = "Phone is too short!";
        break;
      default:
        break;
    }
    setFormInputs((values) => ({ ...values, [name]: value }));
    setFormErrors(newErrors);
  };
  const formValid = () => {
    const { firstName, email, phone } = formInputs;
    const newErrors = {};
    // firstName errors
    if (!firstName || firstName === "")
      newErrors.firstName = "First name cannot be blank!";
    else if (firstName.length < 2)
      newErrors.firstName = "First name is too short!";
    // email errors
    if (!email || email === "") newErrors.email = "Email cannot be blank!";
    else if (email !== "" && !regExp.test(email))
      newErrors.email = "Email is not valid!";
    // phone errors
    if (!phone || phone === "") newErrors.phone = "Phone cannot be blank!";
    else if ((phone !== undefined || phone !== "") && phone.length < 10)
      newErrors.phone = "Phone is too short!";

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAlertDanger("");
    setAlertSuccess("");
    const newErrors = formValid();
    setFormErrors(newErrors);
    if (!(Object.keys(newErrors).length > 0)) {
      setFormInputs({});
      axios
        .post("record/save-record", {
          saveData: {
            text_1: formInputs.firstName,
            text_2: formInputs.lastName,
            text_3: formInputs.email,
            text_4: formInputs.phone,
            long_text_1: formInputs.desc,
            text_5: formInputs.organizationAffiliation,
            text_6: formInputs.jobTitle,
          },
          tableId: process.env.REACT_APP_CQ_TABLE_ID,
        })
        .then((response) => {
          setAlertSuccess(
            "Thank you for contacting Maragin. Please expect a response within the next business day."
          );
        })
        .catch((error) => {
          console.log("error", error);
          setAlertDanger(error);
        });
    }
  };

  return (
    <>
      <main id="main">
        <section className="about-us-section">
          <div className="container">
            <h2
              className="subheading-text-p aos-init aos-animate"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div style={{ textAlign: "center", marginBottom: 30 }}>
                <span style={{ color: "inherit", wordSpacing: "normal" }}>
                  Contact Us
                </span>
              </div>
            </h2>

            <div className="row">
              <div className="col-md-5">
                <ul className="common-links-contact-us">
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href = "tel:+1-650-743-6998";
                        e.preventDefault();
                      }}
                    >
                      <img
                        src={images["cont-phone.png"]}
                        alt="+1 (650) 743 6998"
                      />
                      <span>+1 (650) 743 6998</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href = "mailto:info@maragin.org";
                        e.preventDefault();
                      }}
                    >
                      <img
                        src={images["cont-mail.png"]}
                        alt="info@maragin.org"
                      />
                      <span>info@maragin.org</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img
                        src={images["placemarker.png"]}
                        alt="1551 Corporate Dr. Irving, TX 75038"
                      />
                      <span>1551 Corporate Dr. Irving, TX 75038</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-7">
                <div
                  className="contact-form-div contact-form-div-new"
                  style={{ background: "#F9F9F9" }}
                >
                  <p className="about-us red-text-heading-up">
                    Contact MARAGIN with questions and collaboration
                    opportunities
                  </p>
                  {(alertDanger || Object.entries(formErrors).length > 0) && (
                    <div className="alert alert-danger" role="alert">
                      <ul style={{ marginBottom: 0 }}>
                        {Object.entries(formErrors || {}).map(
                          ([prop, value]) => {
                            return (
                              value && (
                                <li className="error-message" key={prop}>
                                  {value}
                                </li>
                              )
                            );
                          }
                        )}
                      </ul>
                      {alertDanger}
                    </div>
                  )}
                  {alertSuccess && (
                    <div className="alert alert-success" role="alert">
                      {alertSuccess}
                    </div>
                  )}
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <div className="row">
                      <div className="col-6">
                        <input
                          className={`form-control form-control-lg input-btn-value ${
                            formErrors && formErrors.firstName ? "error" : ""
                          }`}
                          type="text"
                          placeholder="First Name"
                          aria-label="firstName"
                          name="firstName"
                          value={formInputs.firstName || ""}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          className={`form-control form-control-lg input-btn-value ${
                            formErrors && formErrors.lastName ? "error" : ""
                          }`}
                          type="text"
                          placeholder="
                          Last Name"
                          aria-label="lastName"
                          name="lastName"
                          value={formInputs.lastName || ""}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          className={`form-control form-control-lg input-btn-value ${
                            formErrors && formErrors.organizationAffiliation
                              ? "error"
                              : ""
                          }`}
                          type="text"
                          placeholder="Organization Affiliation"
                          aria-label="organizationAffiliation"
                          name="organizationAffiliation"
                          value={formInputs.organizationAffiliation || ""}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          className={`form-control form-control-lg input-btn-value ${
                            formErrors && formErrors.jobTitle ? "error" : ""
                          }`}
                          type="text"
                          placeholder="Job Title"
                          aria-label="jobTitle"
                          name="jobTitle"
                          value={formInputs.jobTitle || ""}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          className={`form-control form-control-lg input-btn-value ${
                            formErrors && formErrors.email ? "error" : ""
                          }`}
                          type="email"
                          placeholder="Email"
                          aria-label="email"
                          name="email"
                          value={formInputs.email || ""}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          className={`form-control form-control-lg input-btn-value ${
                            formErrors && formErrors.phone ? "error" : ""
                          }`}
                          type="number"
                          placeholder="
                          Phone"
                          aria-label="phone"
                          name="phone"
                          value={formInputs.phone || ""}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12">
                        <textarea
                          className={`form-control ${
                            formErrors && formErrors.desc ? "error" : ""
                          }`}
                          placeholder="Enter Message"
                          style={{ height: "100px" }}
                          aria-label="desc"
                          name="desc"
                          value={formInputs.desc || ""}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn msg-btn common-buttons-medna"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
